.AddBookmark {
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .5);
}

.AddBookmark__form {
  position: relative;
  background-color: #fefefe;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 16px;
  padding-top: 40px;
  padding-bottom: 20px;
  width: 82%;
  max-height: 80vh;
}

.AddBookmark__form label {
  display: block;
}
